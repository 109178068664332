import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { InlineWidget } from "react-calendly";
import { Helmet } from "react-helmet";
import { BsGraphUpArrow } from "react-icons/bs";
import { FaGoogle } from "react-icons/fa";
import Faq from "../../components/faq";
import Hero from "../../components/hero";
import Layout from "../../components/layout";
import OSSection from "../../components/other-services-section";
import OASection from "../../components/our-approach-section";

const TrainingPageDubai = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Hero-Homepage" }) {
				altText
				mediaDetails {
					height
					width
				}
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			sideImg1: wpMediaItem(title: { eq: "Google-image" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			img1: wpMediaItem(title: { eq: "Web-Design-New" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			trainingImg1: wpMediaItem(title: { eq: "training-image-1" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			trainingImg2: wpMediaItem(title: { eq: "training-image-2" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			icon: wpMediaItem(title: { eq: "icon" }) {
				link
			}
			wpSeoPage(title: { eq: "Training" }) {
				contentForHeadTag {
					content
				}
				seo {
					metaDesc
					title
					twitterImage {
						altText
						mediaDetails {
							height
							width
						}
						link
						gatsbyImage(
							width: 1920
							quality: 75
							formats: [WEBP]
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
					opengraphTitle
					opengraphDescription
				}
			}
			sarahImg: wpMediaItem(title: { eq: "sarah-bennett-fullpage-2" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}

			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const schema = data.wpSeoPage.contentForHeadTag.content;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Dubai",
				item: {
					url: `${siteUrl}/dubai`,
					id: `${siteUrl}/dubai`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: "Training",
				item: {
					url: `${siteUrl}/dubai/training`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<Helmet>
				{" "}
				<script type="application/ld+json">{schema}</script>
			</Helmet>
			{/* <LocalBusinessSchema /> */}
			<GatsbySeo
				title={data.wpSeoPage.seo.title}
				description={data.wpSeoPage.seo.metaDesc}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/dubai/training`,
					title: `${data.wpSeoPage.seo.opengraphTitle}`,
					description: `${data.wpSeoPage.seo.opengraphDescription}`,
					images: [
						{
							url: `${data.wpSeoPage.seo.twitterImage.link}`,
							width: `${data.wpSeoPage.seo.twitterImage.mediaDetails.width}`,
							height: `${data.wpSeoPage.seo.twitterImage.mediaDetails.height}`,
							alt: `${data.wpSeoPage.seo.twitterImage.altText}`,
						},
					],
				}}
			/>

			<Layout dubai>
				<section>
					<Hero
						dubai
						button1="primary-link-dark"
						button1Text="Get in touch"
						button1Link="/dubai/contact-us"
						button1Var="white"
						button2={null}
						button3={null}
						backgroundImage={data.heroImg.gatsbyImage}
						title={`TRAINING & \n<span class="text-secondary">CONSULTANCY DUBAI</span>`}
						description="Personalised training to build up your digital marketing skills."
					/>
				</section>
				{/* <section className="py-5 py-lg-7 bg-light-grey d-none d-lg-block">
					<Container className="p-5 bg-white">
						<Row className="justify-content-center gy-4">
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#intro"
								>
									Introduction
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#training"
								>
									Our training
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#deliver"
								>
									How we deliver
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#pricing"
								>
									Pricing
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#faq"
								>
									FAQ's
								</a>
							</Col>
						</Row>
					</Container>
				</section> */}
				<section id="intro" className="py-5 bg-light-grey py-lg-7">
					<Container>
						<Row className="align-items-center">
							<Col className="text-center ">
								<h2 className="mb-5 display-5 text-primary">
									Looking to enhance your skills beyond what online courses
									offer?
								</h2>
								<p className="text-start text-md-center">
									Do you want the knowledge and skills to handle your digital
									marketing on your own? If you're seeking to take charge of
									your digital marketing journey, RJM Digital's training and
									consultancy services for marketing teams and agencies can
									equip you with the skills and knowledge needed to manage your
									digital marketing in-house.
								</p>
								<p className="text-start text-md-center">
									We offer tailored and specialised training sessions,
									customised according to your level of expertise and business
									objectives. Our team of experts will teach you the latest
									techniques for boosting website traffic, enhancing
									click-through rates, and optimising your ad spend.
								</p>
								<p className="text-start text-md-center">
									Get in touch with us today to explore our customised training
									and consultancy programmes.
								</p>
							</Col>
						</Row>
					</Container>
				</section>

				<section id="training" className="py-5 bg-light-grey py-lg-7">
					<Container>
						<Row className="align-items-center gx-lg-6 gx-xl-7 gx-6">
							<Col lg={6}>
								<div
									style={{
										overflow: "hidden",
										borderRadius: "15px",
										boxShadow: "0px 3px 99px #00000029",
									}}
								>
									<GatsbyImage
										className="w-100"
										image={data.img1.gatsbyImage}
										alt={data.img1?.altText}
									/>
								</div>
							</Col>
							<Col lg={6} className="">
								<h2 className="mt-5 mb-5 display-5 text-primary mt-lg-0">
									Training RJM Digital offer
								</h2>
								<h3 className="text-primary">SEO</h3>
								<p>
									Usually, when people search on Google, the first 3 websites
									that appear in the search results receive over 52% of all
									clicks. We will teach you how to improve your rankings and get
									the top spots on Google, Bing and more.
								</p>
								<h3 className="text-primary">Pay-per-click</h3>
								<p>
									Pay-per-click (PPC) advertising on search engines is a rapid
									way to get your website to the top of the search results page
									for a particular keyword. We will train you to use these tools
									effectively which will result in an increase in the number of
									leads that you get and at a lower cost-per-click.
								</p>
								<Button
									className="px-4 py-2 mt-4 cta-btn w-100 w-md-auto fs-5 white-link-yellow"
									variant="primary"
									as={Link}
									to="/dubai/contact-us"
								>
									Get in touch
								</Button>
							</Col>
						</Row>
					</Container>
				</section>

				<section
					id="deliver"
					className="py-5 bg-light-grey position-relative py-lg-7"
				>
					<div className="position-relative bg-med-grey">
						<Container className="pt-4 pb-5 pt-lg-10 pb-lg-7">
							<Row className="mt-5 align-items-center">
								<Col lg={6} className="">
									<h2 className="mb-4 display-5 text-primary">
										Unsure if our training would be useful for you?
									</h2>
									<p>Here are a few key advantages of in-house training:</p>
									<ul>
										<li>Customised for your specific business and industry</li>
										<li>Quickly implement new SEO and PPC campaigns.</li>
										<li>
											Offers a quicker return on investment by eliminating
											third-party agency fees.
										</li>
									</ul>

									<p>
										In addition we also offer ad hoc phone and email support to
										help you with any queries you need immediate support on.
									</p>
									<Button
										className="px-4 py-2 mt-4 cta-btn w-100 w-md-auto fs-5 white-link-yellow"
										variant="primary"
										as={Link}
										to="/dubai/contact-us"
									>
										Talk to an expert
									</Button>
								</Col>
							</Row>
						</Container>
					</div>
					<div className="position-relative top-5rem">
						<Container>
							<Row className="mt-4 g-5 gy-lg-3 mt-lg-0 justify-content-end">
								<Col lg={4}>
									<h3 className=" fs-2 mt-lg-9 text-primary">
										Your bespoke training
									</h3>
									<p>
										Typically when users search on Google, the top 3 ranking
										websites get more than 52% of all clicks.
									</p>
									<p>
										Search engine optimisation (SEO) is how you can enhance your
										website so that search engines such as Google and Bing rank
										you higher on their results page.
									</p>
								</Col>
								<Col lg={4}>
									<div
										className="bg-white"
										style={{
											borderRadius: " 15px",
											boxShadow: "0px 3px 50px #00000012",
											width: "100%",
											height: "25rem",
											overflow: "hidden",
										}}
									>
										<GatsbyImage
											className="w-100 h-100"
											image={data.trainingImg1.gatsbyImage}
											alt={data.trainingImg1?.altText}
										/>
									</div>
								</Col>
								<Col lg={4}>
									<div
										className="bg-white"
										style={{
											borderRadius: " 15px",
											boxShadow: "0px 3px 50px #00000012",
											width: "100%",
											height: "25rem",
											overflow: "hidden",
										}}
									>
										<GatsbyImage
											className="w-100 h-100"
											image={data.trainingImg2.gatsbyImage}
											alt={data.trainingImg2?.altText}
										/>
									</div>
								</Col>
							</Row>
						</Container>
					</div>
				</section>
				<div id="approach">
					<OASection
						imgHeight="55rem"
						image={data.sarahImg.gatsbyImage}
						imageAlt={data.sarahImg?.altText}
						heading1="Discovery meeting"
						brief="To customise the training plan according to your specific business requirements, we will schedule a discovery meeting to identify knowledge gaps and determine your desired outcomes from the training."
						heading2="Plan"
						design="We will begin building the bespoke training programme covering all the essential topics that you need to understand. Once the plan has been confirmed, we will set the dates and times over Zoom for each of the sessions that is convenient for you and your team."
						heading3="Delivery"
						build="Over the entirety of the programme RJM Digital can provide group and one to one sessions as well as any ad hoc help to answer any questions or queries that may arise. Sessions will need to be booked 48 hours in advance, using our online calendar."
						heading4="Ongoing support"
						launch="Any questions you may have in between the Zoom sessions, our expert team will help you over email or telephone."
					/>
				</div>
				<section className="pb-5 py-lg-7 bg-light-grey">
					<Container>
						<Row>
							<Col>
								<h2 className="pb-5 mt-5 mb-5 text-center display-5 text-uppercase text-primary">
									Our most common training topics
								</h2>
							</Col>
						</Row>
						<Row className="g-5">
							<Col className="text-center" lg={6}>
								<BsGraphUpArrow className="mb-4 display-2 mb-md-5 text-primary" />
								<h3 className="pb-4 text-center fs-2">Our SEO training</h3>
								<ul className="text-start">
									<li>Keyword research</li>
									<li>How to write effective SEO content</li>
									<li>
										How to improve performance of your website for technical SEO
									</li>
									<li>How to acquire the right backlinks for your business</li>
								</ul>
							</Col>
							<Col className="text-center" lg={6}>
								<FaGoogle className="mb-4 display-2 mb-md-5 text-primary" />
								<h3 className="pb-4 text-center fs-2">
									Our Google Ad training
								</h3>
								<ul className="text-start">
									<li>How to evaluate the cost-per-click of each keyword</li>
									<li>How to create and manage a campaign</li>
									<li>How to write an effective ad</li>
									<li>Keyword bidding strategies</li>
									<li> How to lower the cost over time of your campaigns</li>
								</ul>
							</Col>
						</Row>
					</Container>
				</section>
				{/* <section id="pricing">
					<Container>
						<Row>
							<Col>
								<h2 className="pb-5 mt-5 mb-5 text-center display-5 text-primary">
									PRICING
								</h2>
							</Col>
						</Row>
						<Row className="justify-content-center ">
							<PricingCardTraining
								dubai
								btnColour="secondary"
								title="AD - HOC"
								subText="For specific queries about SEO or Google Ads for your website. "
								price="AED450"
								hourly
								middle
							/>
							<PricingCardTraining
								dubai
								btnColour="light-blue"
								title="MONTHLY"
								subText="For businesses who are looking for a long term support and training on upskilling their staff"
								price="AED1750"
								monthly
								middle
							/>
						</Row>
					</Container>
				</section> */}
				<section className="pt-5 pt-lg-7 bg-light-grey">
					<Container>
						<Row id="form">
							<Col className="text-center">
								{" "}
								<h2 className="mb-3 display-5 text-primary">
									Talk to an expert
								</h2>
								<p>
									Book a time and day that works for you through our online
									calendar to discuss your training project.
								</p>
							</Col>
						</Row>
						<Row>
							<Col className="d-xl-none">
								<InlineWidget
									className="calender"
									styles={{ minWidth: "100%", height: "1150px" }}
									url="https://calendly.com/robbie-rjm-digital/rjm-discovery-meeting?hide_gdpr_banner=1"
								/>
							</Col>
							<Col className="d-none d-xl-block">
								<InlineWidget
									className="calender"
									styles={{ minWidth: "100%", height: "700px" }}
									url="https://calendly.com/robbie-rjm-digital/rjm-discovery-meeting?hide_gdpr_banner=1"
								/>
							</Col>
						</Row>
					</Container>
				</section>
				<div id="faq">
					<Faq page="Training" />
				</div>
				<OSSection
					dubai
					link3="/dubai/web-design"
					text3="WEB DESIGN"
					image={data.sideImg1.gatsbyImage}
					imageAlt={data.sideImg1?.altText}
				/>
			</Layout>
		</>
	);
};

export default TrainingPageDubai;
